export const previewAccountTableData = [
    {
        col_p: "AUDNZD",
        col_o: "1.0825",
        col_c: "1.0845",
        col_rr: "2.44",
        col_sl: "1.07595",
        col_tp: "1.10500",
        col_m_: "Lorem Ipsum",
        col_d_: "7/1/2023",
        col_r_: "0.32",
        col_p_: "0.64%",
        col_v_: "$567.23",
        col_d: "Long",
        col_t: "4H",
    },
    {
        col_p: "NZDUSD",
        col_o: "0.61850",
        col_c: "0.61354",
        col_rr: "3.11",
        col_sl: "0.62445",
        col_tp: "0.60000",
        col_m_: "Hammer Candle",
        col_d_: "6/28/2023",
        col_r_: "0.84",
        col_p_: "1.68%",
        col_v_: "$1234.55",
        col_d: "Short",
        col_t: "1H",
    },
    {
        col_p: "GBPCHF",
        col_o: "1.1400",
        col_c: "1.1325",
        col_rr: "1.85",
        col_sl: "1.1325",
        col_tp: "1.1450",
        col_m_: "Buy Order",
        col_d_: "10/6/2023",
        col_r_: "-1",
        col_p_: "-2%",
        col_v_: "-$2000.00",
        col_d: "Long",
        col_t: "1D",
    },
];
